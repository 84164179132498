// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;400;500&display=swap"  );

@import 'scss/customAgGrid';
@import 'scss/staticReports';
@import 'scss/customCalendar';
@import 'scss/dashboard.scss';

body.hierarchy{
    footer,
    .list_container
    {
         background-color: #f5f8fa;
    }
}

.MuiModal-root   ~ .customPopover {
        z-index: 1500;
     }
 
 
 .customPopover {
    z-index: 100;
 }
 
// .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-ltr .ag-cell-range-single-cell, .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-rtl .ag-cell-range-single-cell, .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle{
//     border :2px solid #006e7f !important;
// }

